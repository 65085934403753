<template>
  <i-cards
    :db='dbJSON'></i-cards>
</template>

<script>
import Cards from '@/components/MainCards'
import solidDB from '@/store/data/solid-db.json'

export default {
  components: {
    'i-cards': Cards
  },
  computed: {
    dbJSON () {
      return solidDB
    }
  }
}
</script>
