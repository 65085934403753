<template>
  <v-app>
    <v-navigation-drawer
      app
      fixed
      nav
      permanent
      v-model='drawer'
      :mini-variant.sync='mini'
    >
      <v-list>
        <v-list-item link>
          <v-list-item-content>
            <v-list-item-title class='title'>
              <v-icon color="red">mdi-chart-bubble</v-icon>
              ColorsCloud
            </v-list-item-title>
          </v-list-item-content>

        </v-list-item>
      </v-list>

      <v-divider></v-divider>
      <v-list
        nav
        dense
        >
        <v-list-item
          to="/home">
          <v-list-item-icon>
            <v-icon>mdi-clipboard-search</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-for='(item, key) in menu'
          :key='key'
          :to='item.path'
          >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
          <v-badge
            color="pink"
            :content="item.dbNum"
            inline
          >
          </v-badge>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      fixed
      color='#ffffff'
      :elevation='elevation'
    >
      <v-btn
          icon
          @click.stop='mini = !mini'
        >
          <v-app-bar-nav-icon>
          </v-app-bar-nav-icon>
      </v-btn>
      <v-toolbar-title
        class="ml-0 pl-4"
        style="padding-right: 20px"
      >
        <router-link to="/">
        <v-icon color="red">mdi-chart-bubble</v-icon>
        </router-link>
      </v-toolbar-title>

      <i-header-button
        href="https://github.com/benben-miao"
        target="_blank"
        icon="mdi-github"
        title="Github"
        tips="Github of Author"
      />
      <v-spacer/>
      <i-header-button
        class="hide-mobile"
        href="https://bioscitools.github.io"
        target="_blank"
        icon="mdi-dna"
        title="BioSciTools"
        tips="BioSciTools Website"
      />
    </v-app-bar>

    <v-main>
      <v-container fluid>
      <router-view/>
    </v-container>
    </v-main>

    <v-footer app>
    </v-footer>
  </v-app>
</template>

<script>
import HeaderButton from '@/components/HeaderButton'
import solidDB from '@/store/data/solid-db.json'
import gradualDB from '@/store/data/gradual-db.json'

export default {
  name: 'App',
  components: {
    'i-header-button': HeaderButton
  },
  data: () => ({
    drawer: true,
    menu: [
      {
        title: 'Solid',
        path: '/solid',
        icon: 'mdi-card-minus',
        dbNum: solidDB.length
      },
      {
        title: 'Gradual',
        path: '/gradual',
        icon: 'mdi-card-plus',
        dbNum: gradualDB.length
      },
      {
        title: 'SolidPicker',
        path: '/solid-picker',
        icon: 'mdi-bird',
        dbNum: ''
      },
      {
        title: 'GradualPicker',
        path: '/gradual-picker',
        icon: 'mdi-flower',
        dbNum: ''
      },
      {
        title: 'About',
        path: '/about',
        icon: 'mdi-account-box',
        dbNum: ''
      }
    ],
    mini: false
  }),
  computed: {
    geneDB () {
      return solidDB
    },
    proteinDB () {
      return gradualDB
    }
  }
}
</script>

<style scoped>
.v-app-bar {
  background: rgba(255, 255, 255, 0.9) !important;
}
</style>
