<template>
  <div>
    <div
      style='
        width: 92%;
        margin: auto;
        box-shadow: 0px 0px 20px #aaaaaa;
        border-radius: 10px;
      '
    >
      <!-- <v-carousel
        cycle
        height='100%'
        delimiter-icon='mdi-minus'
        show-arrows-on-hover
        style='border-radius: 10px'
      >
        <v-carousel-item
          v-for='(slide, i) in slides'
          :key='i'
          :src='slide.src'>
          <template v-slot:placeholder>
            <v-row
              class='fill-height ma-0'
              align='center'
              justify='center'
            >
              <v-progress-circular
                indeterminate
                color='grey lighten-5'
              ></v-progress-circular>
            </v-row>
          </template>
        </v-carousel-item>
      </v-carousel> -->
    </div>
    <v-row align="center" justify="center">
      <v-col cols="auto" align-self="center" style="width: 92%">
        <h2>1. Colors Sika Deer</h2>
        <v-hover>
          <v-card
            rounded
            slot-scope="{ hover }"
            :class="`elevation-${hover ? 8 : 4}`"
            class="transition-swing mx-auto"
            width="100%"
            style="padding: 10px; top: 80px; position: relative; margin-bottom: 5%;"
            >
            <v-sheet
              class="v-sheet--offset mx-auto"
              style="border-radius: 10px; top: -80px; position: relative;"
              max-width="calc(100% - 5px)"
              >
              <div
                class="color-card"
                :aspect-ratio="16/9"
                >
                <!-- <iframe
                  src="/cat/index.html"
                  frameborder=0
                  width=100%
                  height=100%
                  style="box-shadow: 0px 0px 20px #cccccc; border-radius: 10px;"
                >
                </iframe> -->
                <iframe
                  width=100%
                  height=100%
                  src="/html/flower.html"
                  frameborder="0" allowvr allowfullscreen mozallowfullscreen webkitallowfullscreen
                  style="box-shadow: 0px 0px 20px #cccccc; border-radius: 10px;"
                  >
                </iframe>
                <!-- <img src="../assets/image/ColorCards.png" style="width: 100%; height: 100%; box-shadow: 0px 0px 20px #cccccc; border-radius: 10px;"/> -->
              </div>
            </v-sheet>
            <v-card-title
              primary-title
              style="padding: 5px 10px; top: -60px; position: relative;"
              >
              Author Introduction:
            </v-card-title>
            <v-divider style="top: -60px; position: relative;"></v-divider>
            <v-card-text
              style="padding: 5px 10px; margin: 0px; text-align: left; top: -60px; position: relative;"
              >
              <h3><b>Developer:</b> benben-miao</h3><br>
              <h3><b>Github:</b> <a href="https://github.com/benben-miao" target="_blank">https://github.com/benben-miao</a></h3><br>
              <h3><b>Hiplot Cloud Platform:</b> <a href="https://hiplot-academic.com/" target="_blank">https://hiplot-academic.com/</a></h3><br>
              <h3><b>BioSciTools Software:</b> <a href="https://bioscitools.netlify.app" target="_blank">https://bioscitools.netlify.app</a></h3><br>
              <h3><b>BioNav for Bioinformatics:</b> <a href="https://bio-nav.netlify.app" target="_blank">https://bio-nav.netlify.app</a></h3><br>
              <h3><b>ColorsCloud for Designer:</b> <a href="https://colors-cloud.netlify.app" target="_blank">https://colors-cloud.netlify.app</a></h3><br>
              <h3><b>Email:</b> benben.miao@outlook.com</h3><br>
              <h3><b>Info:</b> Doctor of Bioinformatics, Xiamen University, Xiamen, Fujian, China.</h3><br>
              <h3><b>Code:</b> Python, Java, R, Julia, Perl, Ruby, GO, HTML, CSS, Javascript, Node.JS, VUE.JS, Spring Boot, Docker, Kubernetes, etc.</h3>
            </v-card-text>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
    <br>
    <div style='width: 90%; margin: auto'>
      <h2>2. Solid Colors</h2>
      <i-main-cards :db='dbs1'></i-main-cards>
    </div>
    <br /><v-divider></v-divider><br />
    <div style='width: 90%; margin: auto'>
      <h2>3. Gradual Colors</h2>
      <i-main-cards :db='dbs2'></i-main-cards>
    </div>
    <br /><v-divider></v-divider><br />
    <div>
      <v-card rounded class='mt-4 mx-auto' max-width='90%' color='orange'>
        <v-sheet
          class='v-sheet--offset mx-auto'
          color='#ff1744aa'
          elevation='10'
          max-width='calc(100% - 30px)'
          rounded
        >
          <v-sparkline
            :labels='date'
            :value='numDB'
            color='black'
            line-width='2'
            padding='25'
            smooth='1'
            stroke-linecap='round'
            type='trend'
            auto-line-width='true'
            auto-draw
            :gradient='gradient'
          ></v-sparkline>
        </v-sheet>

        <v-card-text class='pt-0' style='color: white'>
          <div class='text-h6 mb-2'>Number of color cards.</div>
          <v-divider class='my-2'></v-divider>
          <v-icon class='mr-2'> mdi-clock </v-icon>
          <span class='text-caption'
            >The color cards will update for long time.</span
          >
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import MainCards from '@/components/MainCards'

export default {
  components: {
    'i-main-cards': MainCards
  },
  data () {
    return {
      // slides: [
      //   {
      //     src: 'https://s1.ax1x.com/2020/07/12/U847rV.png'
      //   },
      //   {
      //     src: 'https://s1.ax1x.com/2020/07/12/U84LaF.png'
      //   },
      //   {
      //     src: 'https://s1.ax1x.com/2020/07/12/U8452n.png'
      //   },
      //   {
      //     src: 'https://s1.ax1x.com/2020/07/12/U84HbT.png'
      //   },
      //   {
      //     src: 'https://s1.ax1x.com/2020/07/12/U84Ivq.png'
      //   },
      //   {
      //     src: 'https://s1.ax1x.com/2020/07/12/U84TK0.png'
      //   },
      //   {
      //     src: 'https://s1.ax1x.com/2020/07/12/U84qVU.png'
      //   }
      // ],
      dbs1: [
        {
          id: 1,
          name: {
            en: 'color'
          },
          css: 'background-color: #d50000;'
        },
        {
          id: 2,
          name: {
            en: 'color'
          },
          css: 'background-color: #ff1744;'
        },
        {
          id: 3,
          name: {
            en: 'color'
          },
          css: 'background-color: #ff5252;'
        },
        {
          id: 4,
          name: {
            en: 'color'
          },
          css: 'background-color: #ff8a80;'
        },
        {
          id: 5,
          name: {
            en: 'color'
          },
          css: 'background-color: #b71c1c;'
        },
        {
          id: 6,
          name: {
            en: 'color'
          },
          css: 'background-color: #c62828;'
        }
      ],
      dbs2: [
        {
          id: 1,
          name: {
            en: 'gradual'
          },
          css: 'background-image: radial-gradient( circle farthest-corner at 12.3% 19.3%,  rgba(85,88,218,1) 0%, rgba(95,209,249,1) 100.2% );'
        },
        {
          id: 1,
          name: {
            en: 'gradual'
          },
          css: 'background: linear-gradient(to right, rgb(242, 112, 156), rgb(255, 148, 114));'
        },
        {
          id: 1,
          name: {
            en: 'gradual'
          },
          css: 'background-image: linear-gradient(to right, rgb(182, 244, 146), rgb(51, 139, 147));'
        },
        {
          id: 1,
          name: {
            en: 'gradual'
          },
          css: 'background-image: linear-gradient( 109.6deg,  rgba(156,252,248,1) 11.2%, rgba(110,123,251,1) 91.1% );'
        },
        {
          id: 1,
          name: {
            en: 'gradual'
          },
          css: 'background-image: linear-gradient( 109.6deg,  rgba(253,199,141,1) 11.3%, rgba(249,143,253,1) 100.2% );'
        },
        {
          id: 1,
          name: {
            en: 'gradual'
          },
          css: 'background-image: linear-gradient(to top, #a18cd1 0%, #fbc2eb 100%);'
        }
      ],
      date: [
        '2021/05/20',
        '2021/05/24',
        '2021/05/28',
        '2021/05/30',
        '2021/06/01'
      ],
      numDB: [0, 10, 20, 30, 38],
      gradient: gradients[3]
    }
  }
}
const gradients = [
  ['#222'],
  ['#42b3f4'],
  ['red', 'orange', 'yellow'],
  ['purple', 'violet'],
  ['#00c6ff', '#F0F', '#FF0'],
  ['#f72047', '#ffd200', '#1feaea']
]
</script>

<style>
.v-sheet--offset {
  top: -30px;
  position: relative;
}
.color-card {
  width: 100%;
  height: 580px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #cccccc;
}
</style>
