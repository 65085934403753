<template>
  <i-cards
    :db='dbJSON'></i-cards>
</template>

<script>
import Cards from '@/components/MainCards'
import gradualDB from '@/store/data/gradual-db.json'

export default {
  components: {
    'i-cards': Cards
  },
  computed: {
    dbJSON () {
      return gradualDB
    }
  }
}
</script>
