<template>
  <v-row
    justify="center"
    align="center"
    class="text-center"
  >
  <v-col cols="12">
    <v-row
      justify="center"
      align="center"
    >
    <v-col
      cols="12"
      :md="cardFlex('md')"
      :lg="cardFlex('lg')"
      :sm="cardFlex('sm')"
      v-for="(item, i) in dbJSON.slice((this.page-1)*this.pageSize,this.page*this.pageSize)"
      v-bind:key="i"
    >
    <v-hover>
      <v-card
        rounded
        slot-scope="{ hover }"
        :class="`elevation-${hover ? 8 : 4}`"
        class="transition-swing mx-auto"
        width="100%"
        style="padding: 5px; top: 20px; position: relative; margin-bottom: 5%;"
        >
        <v-sheet
          class="v-sheet--offset mx-auto"
          style="border-radius: 8px; top: -20px; position: relative;"
          max-width="calc(100% - 5px)"
          >
          <div
            class="color-card"
            :style=item.css
            :aspect-ratio="16/9"
            >
          </div>
        </v-sheet>
        <!-- <v-card-title
          primary-title
          style="padding: 5px 10px; top: -10px; position: relative;"
          >
          <div class="text-left">
            <div style="color:#000000; font-size:1em">
              {{ item.name.en }}
              <v-tooltip
                top
                fixed
                max-width="200"
                >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="green"
                    v-bind="attrs"
                    v-on="on"
                  >mdi-help-box</v-icon>
                </template>
                <h3><v-icon color="orange">mdi-message-bulleted</v-icon>{{ item.name.en }}</h3>
                <h5>{{ item.description.en }}</h5>
              </v-tooltip>
            </div>
          </div>
        </v-card-title> -->
        <!-- <v-divider style="top: -10px; position: relative;"></v-divider> -->
        <v-card-text
          style="padding: 5px 10px; margin: 0px; text-align: left; top: -10px; position: relative;"
          >
          <!-- <div
            style="font-size: 0.8em; height: 45px; color: #888888; text-shadow: 0px 0px 1px #888888"
            >
            {{ item.description.en }}
          </div> -->
          <v-dialog
            overlay-opacity="0"
            max-width="300"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                small
                style="background-color:#dddddd; width:98%; overflow: hidden; font-size: 0.6em; position: relative"
                v-clipboard:copy="item.css"
                v-bind="attrs"
                v-on="on"
                >
                <v-icon left>mdi-gesture-tap-box</v-icon>
                <span>Copy the CSS code! <b style="color:darkorange">{{ item.css.slice(18,25) }}</b></span>
              </v-btn>
            </template>
            <template>
              <v-alert
                border="bottom"
                type="success"
              >
                Copied CSS Style: <p>{{ item.css }}</p>
              </v-alert>
            </template>
          </v-dialog>
          <!-- <v-rating
            v-model="rating"
            color="purple"
            background-color="purple lighten-3"
            empty-icon="$ratingFull"
            half-increments
            hover
            small
            style="bottom: -1.5em; position: relative"
          ></v-rating> -->
        </v-card-text>
      </v-card>
    </v-hover>
    </v-col>
    </v-row>
  </v-col>
    <v-col
      cols="12"
      md="12"
      v-if="this.dbJSON.length > this.pageSize"
    >
      <v-pagination
        v-model="page"
        :length="pageLength"
      ></v-pagination>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ['db'],
  data () {
    return {
      page: 1,
      pageLength: 1,
      pageSize: 24,
      type: 'database',
      update: false,
      isimage: false,
      rating: 5
    }
  },
  watch: {
  },
  computed: {
    dbJSON () {
      return this.db
    }
  },
  mounted () {
    this.updatePageLength()
    this.isimage = true
  },
  methods: {
    updatePageLength () {
      if (this.dbJSON.length > 0) {
        this.pageLength = Math.ceil(this.dbJSON.length / this.pageSize)
      } else {
        this.pageLength = 1
      }
    },
    cardFlex (mode) {
      if (this.dbJSON.slice((this.page - 1) * this.pageSize, this.page * this.pageSize).length < 3 && this.type !== 'database') {
        if (!mode) {
          return 3
        } else {
          return 3
        }
      } else if (this.dbJSON.slice((this.page - 1) * this.pageSize, this.page * this.pageSize).length < 3 && (this.type === 'database')) {
        if (mode === 'md') {
          return 3
        } else if (mode === 'lg') {
          return 2
        }
      } else {
        if (mode === 'md') {
          return 3
        } else if (mode === 'lg') {
          return 2
        } else if (mode === 'sm') {
          return 6
        }
      }
    }
    // onCopy: function (e) {
    //   alert('You just copied: ' + e.text)
    // },
    // onError: function (e) {
    //   alert('Failed to copy text!')
    // }
  }
}
</script>

<style scoped>
.color-card {
  width: 100%;
  height: 200px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px #cccccc;
}
</style>
