<template>
  <v-row align="center" justify="center">
    <v-col cols="auto" align-self="center" style="width: 92%">
      <v-hover>
        <v-card
          rounded
          slot-scope="{ hover }"
          :class="`elevation-${hover ? 8 : 4}`"
          class="transition-swing mx-auto"
          width="100%"
          style="padding: 10px; top: 80px; position: relative; margin-bottom: 5%;"
          >
          <v-sheet
            class="v-sheet--offset mx-auto"
            style="border-radius: 10px; top: -80px; position: relative;"
            max-width="calc(100% - 5px)"
            >
            <div
              class="color-card"
              :aspect-ratio="16/9"
              >
              <iframe
                src="/html/color-clock.html"
                frameborder=0
                width=100%
                height=100%
                style="box-shadow: 0px 0px 20px #cccccc; border-radius: 10px;"
              >
              </iframe>
            </div>
          </v-sheet>
          <v-card-title
            primary-title
            style="padding: 5px 10px; top: -60px; position: relative;"
            >
            Author Introduction:
          </v-card-title>
          <v-divider style="top: -60px; position: relative;"></v-divider>
          <v-card-text
            style="padding: 5px 10px; margin: 0px; text-align: left; top: -60px; position: relative;"
            >
            <h3><b>Developer:</b> benben-miao</h3><br>
              <h3><b>Github:</b> <a href="https://github.com/benben-miao" target="_blank">https://github.com/benben-miao</a></h3><br>
              <h3><b>Hiplot Cloud Platform:</b> <a href="https://hiplot-academic.com/" target="_blank">https://hiplot-academic.com/</a></h3><br>
              <h3><b>BioSciTools Software:</b> <a href="https://bioscitools.netlify.app" target="_blank">https://bioscitools.netlify.app</a></h3><br>
              <h3><b>BioNav for Bioinformatics:</b> <a href="https://bio-nav.netlify.app" target="_blank">https://bio-nav.netlify.app</a></h3><br>
              <h3><b>ColorsCloud for Designer:</b> <a href="https://colors-cloud.netlify.app" target="_blank">https://colors-cloud.netlify.app</a></h3><br>
              <h3><b>Email:</b> benben.miao@outlook.com</h3><br>
              <h3><b>Info:</b> Doctor of Bioinformatics, Xiamen University, Xiamen, Fujian, China.</h3><br>
              <h3><b>Code:</b> Python, Java, R, Julia, Perl, Ruby, GO, HTML, CSS, Javascript, Node.JS, VUE.JS, Spring Boot, Docker, Kubernetes, etc.</h3>
          </v-card-text>
        </v-card>
      </v-hover>
    </v-col>
  </v-row>
</template>

<script>
export default {

}
</script>

<style scoped>
.color-card {
  width: 100%;
  height: 560px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #cccccc;
}
</style>
