import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home'
import Solid from '@/views/Solid'
import Gradual from '@/views/Gradual'
import SolidPicker from '@/views/SolidPicker'
import GradualPicker from '@/views/GradualPicker'
import About from '@/views/About'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/solid',
    name: 'Solid',
    component: Solid
  },
  {
    path: '/gradual',
    name: 'Gradual',
    component: Gradual
  },
  {
    path: '/solid-picker',
    name: 'SolidPicker',
    component: SolidPicker
  },
  {
    path: '/gradual-picker',
    name: 'GradualPicker',
    component: GradualPicker
  },
  {
    path: '/about',
    name: 'About',
    component: About
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
