<template>
  <v-toolbar-title>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          text
          v-on="on"
          :href="href"
          :target="target"
          v-if="href"
        >
          <v-icon left dark>{{ icon }}</v-icon>
          {{ title }}
        </v-btn>
        <v-btn
          icon
          v-on="on"
          :to="to"
          v-else
        >
          <v-icon>{{ icon }}</v-icon>
        </v-btn>
      </template>{{ tips }}
    </v-tooltip>
  </v-toolbar-title>
</template>

<script>
export default {
  props: ['title', 'to', 'href', 'target', 'icon', 'tips']
}
</script>
