<template>
  <v-row align="center" justify="center">
    <v-col cols="auto" align-self="center">
      <v-select
        v-model="colorMode"
        :items='colorModes'
        label="Color Mode"
        style="padding: 20px; box-shadow: 0px 0px 10px #cccccc; border-radius: 5px; margin: 0px 10px;"
      >
      </v-select>
      <v-color-picker
        :width='theWidth'
        canvas-height=300
        dark=false
        class='ma-3'
        :mode='colorMode'
        show-swatches
        swatches-max-height='300px'
        v-model="currentColor"
      ></v-color-picker>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    colorMode: 'rgba',
    colorModes: [
      'rgba',
      'hsla',
      'hexa'
    ],
    theWidth: (window.innerWidth * 0.8)
  }),
  computed () {
    this.theWidth = (window.innerWidth * 0.8)
  }
}
</script>

<style scoped>
.v-color-picker {
  border-radius: 0px;
  box-shadow: 0px 0px 20px #cccccc;
}
</style>
